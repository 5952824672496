import React from 'react'
import styled from 'styled-components'
import Icon from '@material-ui/core/Icon'
import {useServerImage} from 'utils'
import * as Styled from './StyledElements'
import {DiagnosticReason} from 'modules/navigation/types'
import {errorIcon, success, warning} from '../../colors'
import {getSeverity} from './utils'
import {Severity} from './types'

const IMAGE_URL_BY_EVENT_TYPE: {[type: string]: string} = {
  [DiagnosticReason.FORK_OPEN]: '/nsp/close_fork.png',
  [DiagnosticReason.FORK_OPEN_RECOVERED]: '/nsp/close_fork.png',
  [DiagnosticReason.CONFIGURATION_ERROR]: '/nsp/configuration.png',
  [DiagnosticReason.PERSON_DETECTED_DURING_UV]: '/nsp/person_during_uv.png',
  [DiagnosticReason.BATTERY_DOOR_AJAR]: '/nsp/close_battery_door.png',
  [DiagnosticReason.BATTERY_DOOR_AJAR_RECOVERED]: '/nsp/close_battery_door.png',
  [DiagnosticReason.ESTOP]: '/nsp/emergency_pressed.png',
  [DiagnosticReason.ESTOP_RECOVERED]: '/nsp/emergency_pressed.png',
  [DiagnosticReason.DISCONNECTED]: '/nsp/robot_disconnected.gif',
  [DiagnosticReason.DISCONNECTED_LONG_TIME]: '/nsp/robot_disconnected.gif',
  [DiagnosticReason.INTERNET_CONNECTION_LOST]: '/nsp/internet_disconnected.gif',
  [DiagnosticReason.CHECK_ENVIRONMENT]: '/nsp/waiting.gif',
  [DiagnosticReason.CONNECT_CHARGER]: '/nsp/connect_charger.png',
  [DiagnosticReason.CHECK_ROBOT]: '/nsp/robot_check_required.png',
  [DiagnosticReason.ROBOT_IN_CRITICAL_STATE]: '/nsp/critical_error.png',
  [DiagnosticReason.CRITICAL_ERROR]: '/nsp/critical_error.png',
  [DiagnosticReason.DOOR_OPEN_FOR_UVC]: '/sanitization/wauv_wait_for_door.gif',
  [DiagnosticReason.FAILED_TURN_OFF_LAMP]: '/nsp/waiting.gif',
  [DiagnosticReason.FAILED_DISPOSE_LAMP]: '/nsp/waiting.gif',
  [DiagnosticReason.FAILED_GRAB_LAMP]: '/nsp/waiting.gif',
  [DiagnosticReason.HELP_TRAVERSE_DOOR]: '/nsp/waiting.gif',
  [DiagnosticReason.WAITING_CLEARANCE]: '/sanitization/pick-up-lamp.gif',
  [DiagnosticReason.WAITING_CLEARANCE_AIRPORT]: '/nsp/waiting.gif',
  [DiagnosticReason.LAMP_NOT_OPERATIONAL]: '/nsp/uv_device_not_op.png',
  [DiagnosticReason.LAMP_ESTOP_PRESSED]: '/nsp/estop.gif',
  [DiagnosticReason.LAMP_BATTERY_LOW]: '/nsp/battery_low.gif',
  [DiagnosticReason.LAMP_NOT_GRASPED]: '/sanitization/pick-up-lamp.gif',
}

const ICON_CLASS_NAME_BY_EVENT_TYPE: {[type: string]: string} = {
  [DiagnosticReason.POINT_DONE]: 'fa fa-check-circle',
  [DiagnosticReason.POINT_SKIPPED]: 'fa fa-share',
  [DiagnosticReason.DISCONNECTED]: 'fa fa-chain-broken',
  [DiagnosticReason.DISCONNECTED_LONG_TIME]: 'fa fa-chain-broken',
  [DiagnosticReason.CRITICAL_BATTERY]: 'fa fa-battery-empty fa-rotate-270',
  [DiagnosticReason.CRITICAL_ERROR]: 'fa fa-exclamation-triangle',
  [DiagnosticReason.ROBOT_IN_CRITICAL_STATE]: 'fa fa-exclamation-triangle',
}

const DEFAULT_ICON_CLASS_NAME = 'fa fa-question-circle'

interface Props {
  reason: DiagnosticReason
  photo?: string
  fullImage?: boolean
  maxHeight?: number
}

const StyledIcon = styled(Icon)`
    color: ${(props: {severity?: Severity}) => {
    if (props.severity === 'warning') {
      return warning
    }

    if (props.severity === 'normal') {
      return success
    }

    return errorIcon
  }};
    font-size: 18vh!important;
    width: 24vh!important;
    text-align: center;
`

const EventImage = ({
  photo,
  reason,
  fullImage,
  maxHeight,
}: Props) => {
  const serverImage = useServerImage(photo)
  const image = photo ? serverImage : IMAGE_URL_BY_EVENT_TYPE[reason]
  const shouldHaveAnyImage = serverImage || photo || IMAGE_URL_BY_EVENT_TYPE[reason]
  return (
    <Styled.EventImage
      image={image}
      fullImage={fullImage}
      maxHeight={maxHeight}
      isLoading={!image && Boolean(shouldHaveAnyImage)}
    >
      {!shouldHaveAnyImage && (
        <StyledIcon
          className={ICON_CLASS_NAME_BY_EVENT_TYPE[reason] || DEFAULT_ICON_CLASS_NAME}
          severity={getSeverity(reason)}
        />
      )}
    </Styled.EventImage>
  )
}

export default EventImage
