export function scenarioToModule(scenario?: string) {
  if (scenario?.includes('uv_sanitizing')) {
    return 'uv'
  }

  if (scenario?.includes('night_shift_patrol')) {
    return 'nsp'
  }

  if (scenario?.startsWith('wauv')) {
    return 'wauv'
  }

  if (scenario?.includes('security')) {
      return 'security';
  }

  if (scenario?.includes('sentinel')) {
      return 'sentinel';
  }

  if (scenario?.includes('shop')) {
      return 'shop';
  }
}
