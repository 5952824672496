import React, {useState} from 'react'
import {Alarm, Button} from 'components'
import {useModuleAwareTranslation} from 'utils'
import {blueButton, white} from '../../../colors'
import {ActionsWrapper} from '../shared'
import {
  PATROL_COMMAND,
  StateMachineGroup,
} from '../types'
import {soundAlarmOff} from '../../../config'

export interface Props {
  stateGroup?: StateMachineGroup
  hasEventWithAlarm: boolean
  changeState: (command: PATROL_COMMAND, configurationUid?: string) => void
}

const EstopRecoverAction: React.FC<Props> = ({
  changeState,
  stateGroup,
}: Props) => {
  const {t} = useModuleAwareTranslation()
  const [alarmHandled, setAlarmHandled] = useState(false)
  let setAlarmHandledInterval: number

  const isStopConfig: boolean = stateGroup === StateMachineGroup.STOPPED || stateGroup === StateMachineGroup.STOPPED_RECOVERED

  const isEstop = stateGroup === StateMachineGroup.STOPPED

  const handleAlarm = () => {
    setAlarmHandledInterval = setTimeout(() => {
      setAlarmHandled(true)
      clearTimeout(setAlarmHandledInterval)
    }, 250)
  }

  const showAlarm = (
    isEstop &&
    !alarmHandled &&
    !soundAlarmOff
  )
  return (
    <ActionsWrapper>

      {showAlarm ? (
        <Alarm
          onChange={handleAlarm}
          switchProps={{
            $offColor: blueButton,
          }}
          alarmType={'once'}
        />
      ) : (
        <Button
          data-testid="patrol-action-button"
          $backgroundColor={ blueButton }
          $textColor={ white }
          $bold={true}
          onClick={() => {
            changeState(PATROL_COMMAND.RESUME)
          }
          }
        >
          {t(isStopConfig ?
            isEstop ? `Nav_Status button stopped ESTOP`:'Nav_Status button stopped ESTOP_RECOVERED':
            'Nav_Status button Recover from emergency stop')}
        </Button>
      )
      }
    </ActionsWrapper>
  )
}

export default EstopRecoverAction
