import React from 'react'
import {useModuleAwareTranslation, useVariantModuleTranslation} from 'utils'
import {useHistory} from 'react-router-dom'
import {
  GoToElementIcon,
  List,
  ListHeader,
  ListItem,
  ListItemSubText,
  ListItemTextColumn,
  ListItemTextRight,
} from 'components'
import {scenarioToModule} from '../../utils'
import {SettingsConfigurationElement} from '../types'
import {getSettingsRouteForScenario} from '../utils'
import ConfigTooltip from './ConfigTooltip'

interface Props {
  configurations: SettingsConfigurationElement[]
}
const MultipleConfigurationsList = ({
  configurations,
}: Props) => {
  const {t} = useModuleAwareTranslation()
  const {tCreator} = useVariantModuleTranslation()
  const {push} = useHistory()

  const configurationsWithNames = configurations
    .map(configuration => ({
      ...configuration,
      name: configuration.name || t('Nav_Schedule subtitle configurations default name', {index: configuration.index + 1}) || '',
    }))
    .sort((itemA, itemB) => itemA.name.localeCompare(itemB.name))

  const configurationsByModule = configurationsWithNames.reduce((configsByModule, config) => {
    const module = scenarioToModule(config.scenario) || 'none'
    if (!configsByModule[module]) {
      configsByModule[module] = []
    }

    configsByModule[module].push(config)
    return configsByModule
  }, {} as {[module: string]: typeof configurationsWithNames})

  const hasMultipleModules = Object.keys(configurationsByModule).length > 1
  return (
    <>
      {Object.entries(configurationsByModule)
        .sort(([moduleA], [moduleB]) => t(`use-case ${moduleA}`).localeCompare(t(`use-case ${moduleB}`)))
        .map(([module, configs]) => (
          <List key={module}>
            <ListHeader $withTopMargin>
              {t('Nav_Schedule subtitle configurations')}{hasMultipleModules ? ` - ${t(`use-case ${module}`)}` : ''}
            </ListHeader>
            {configs.map(configuration => (
              <ListItem
                key={configuration.id}
                data-testid={`link-room-config-${configuration.id}`}
                fullWidth
                clickable={Boolean(configuration?.scenario)}
                bolder
                onClick={() => {Boolean(configuration?.scenario) && push(`${getSettingsRouteForScenario(configuration.scenario)}?id=${configuration.id}`)}}
              >
                <ListItemTextColumn>
                  <ListItemTextRight>
                    {configuration.name}
                    <ConfigTooltip configUid={configuration.id} />
                  </ListItemTextRight>
                  <ListItemSubText>
                    {tCreator(scenarioToModule(configuration?.scenario))('Nav_Schedule subtitle configurations selected', {
                      count: configuration.selected,
                      total: configuration.total,
                    })}
                  </ListItemSubText>
                </ListItemTextColumn>
                {Boolean(configuration?.scenario) && <GoToElementIcon />}
              </ListItem>
            ))}
          </List>
        ))}
    </>
  )
}

export default MultipleConfigurationsList
