import {TFunction} from 'i18next'
import {PatrolEventsStore} from './patrolEvents/types'
import {ScheduleStore} from './schedule/types'
import {SanitizationLevel} from '../uv/types'
import {Robot, ReminderDisplayItem} from 'types'

export interface Settings {
    rooms: Room[]
    configurationUid: string
    homeUid?: string
    scenario?: string
    name?: string
    robotOnlyFields?: {[key: string]: any}
}
export interface Room {
    action?: PatrolPointAction
    roomId: string
    enabled: boolean
}

export interface Schedule {
    scheduleItems: ScheduleItem[]
}

export interface PeriodicTask {
    startDate: Date
    cronExpression: string
    rruleExpression: string
    weeklyRepetitions: number
}

export interface BackendScheduleItem {
    enabled: boolean
    task: PeriodicTask
    uiOrder?: number
    configurationUid?: string
}

export interface ScheduleItem extends BackendScheduleItem {
    id: string
}

export interface LocationDescription {
    isUnknown?: boolean
    parentId?: string
    [languageCode: string]: string | undefined | boolean
}

export interface LocationsConfiguration {
    [moduleName: string]: {
        [field: string]: string | number | boolean
    }
}

export interface Locations {
    [locationId: string]: LocationDescription
}

export enum PATROL_COMMAND {
    START = 'START',
    PAUSE = 'PAUSE',
    RESUME = 'RESUME',
    HOME = 'HOME',
    DISMISS_CLIENT_EVENT = 'DISMISS_CLIENT_EVENT',
}

export enum PatrolEventLevel {
    URGENT = 'URGENT',
    CAUTION = 'CAUTION',
    NOMINAL = 'NOMINAL',
}

export enum DiagnosticReason {
    POINT_DONE = 'POINT_DONE',
    POINT_SKIPPED = 'POINT_SKIPPED',
    PERSON_NOT_IN_BED = 'PERSON_NOT_IN_BED',
    PERSON_AT_RISK = 'PERSON_AT_RISK',
    PERSON_IN_HALLWAY = 'PERSON_IN_HALLWAY',
    PERSON_IN_DISINFECTING_AREA = 'PERSON_IN_DISINFECTING_AREA',
    CONNECT_CHARGER = 'CONNECT_CHARGER',
    CHECK_ENVIRONMENT = 'CHECK_ENVIRONMENT',
    CHECK_ROBOT = 'CHECK_ROBOT',
    ESTOP = 'ESTOP',
    ESTOP_RECOVERED = 'ESTOP_RECOVERED',
    FORK_OPEN = 'FORK_OPEN',
    FORK_OPEN_RECOVERED = 'FORK_OPEN_RECOVERED',
    BATTERY_DOOR_AJAR = 'BATTERY_DOOR_AJAR',
    BATTERY_DOOR_AJAR_RECOVERED = 'BATTERY_DOOR_AJAR_RECOVERED',
    CRITICAL_ERROR = 'CRITICAL_ERROR',
    CONFIGURATION_ERROR = 'CONFIGURATION_ERROR',
    PERSON_DETECTED_DURING_UV = 'PERSON_DETECTED_DURING_UV',

    WAITING_FOR_GRIPPER_CONTENT_RESCUE = 'WAITING_FOR_GRIPPER_CONTENT_RESCUE',

    SKIPPED_DUE_TO_ONGOING_PATROL = 'SKIPPED_DUE_TO_ONGOING_PATROL',

    DOOR_OPEN_FOR_UVC = 'DOOR_OPEN_FOR_UVC', // Door are not closed and robot wants to start UVC disinfection
    FAILED_TURN_OFF_LAMP = 'FAILED_TURN_OFF_LAMP',
    FAILED_DISPOSE_LAMP = 'FAILED_DISPOSE_LAMP',
    FAILED_GRAB_LAMP = 'FAILED_GRAB_LAMP',
    HELP_TRAVERSE_DOOR = 'HELP_TRAVERSE_DOOR',
    WAITING_CLEARANCE = 'WAITING_CLEARANCE',
    WAITING_CLEARANCE_AIRPORT = 'WAITING_CLEARANCE_AIRPORT',
    LAMP_NOT_OPERATIONAL = 'LAMP_NOT_OPERATIONAL',
    LAMP_ESTOP_PRESSED = 'LAMP_ESTOP_PRESSED',
    LAMP_BATTERY_LOW = 'LAMP_BATTERY_LOW',
    LAMP_NOT_GRASPED = 'LAMP_NOT_GRASPED',

    // web-created events
    DISCONNECTED = 'DISCONNECTED',
    DISCONNECTED_LONG_TIME = 'DISCONNECTED_LONG_TIME',
    INTERNET_CONNECTION_LOST = 'INTERNET_CONNECTION_LOST',
    ROBOT_IN_CRITICAL_STATE = 'ROBOT_IN_CRITICAL_STATE',
    CRITICAL_BATTERY = 'CRITICAL_BATTERY',
}

export enum SkipReason {
    TYPE_PERSON_DETECTED_DURING_UVC = 'PERSON_DETECTED_DURING_UVC',
    TYPE_PATH_BLOCKED = 'PATH_BLOCKED',
    TYPE_OBJECT_OBSTRUCTED = 'OBJECT_OBSTRUCTED',
    TYPE_OBJECT_NOT_FOUND = 'OBJECT_NOT_FOUND',
    TYPE_NO_EMPTY_DISPOSE_LOCATION = 'NO_EMPTY_DISPOSE_LOCATION',
    TYPE_FAILED_TO_DISPOSE_OBJECT = 'FAILED_TO_DISPOSE_OBJECT',
    TYPE_FAILED_TO_GRAB_OBJECT = 'FAILED_TO_GRAB_OBJECT',
    TYPE_UV_LAMP_MALFUNCTIONING = 'UV_LAMP_MALFUNCTIONING',
    TYPE_CANCELLED_BY_USER = 'CANCELLED_BY_USER',
}

export const personRelatedEvents: Array<DiagnosticReason | undefined> = [
  DiagnosticReason.PERSON_NOT_IN_BED,
  DiagnosticReason.PERSON_AT_RISK,
  DiagnosticReason.PERSON_IN_HALLWAY,
  DiagnosticReason.PERSON_IN_DISINFECTING_AREA,
]

export const alwaysSingleLocationEvents = [
  DiagnosticReason.POINT_SKIPPED,
  DiagnosticReason.POINT_DONE,
  DiagnosticReason.PERSON_NOT_IN_BED,
]

export enum PatrolStartFinishReasons {
    SCHEDULED_START = 'SCHEDULED_START',
    USER_COMMANDED_START = 'USER_COMMANDED_START',

    TASK_FINISHED = 'TASK_FINISHED',
    USER_COMMANDED_HOME = 'USER_COMMANDED_HOME',

    SKIPPED_DUE_TO_ONGOING_PATROL = 'SKIPPED_DUE_TO_ONGOING_PATROL',
}

export interface SimplePatrol {
    id: string
    status: PatrolEventLevel
    startedAt: Date
    finishedAt: Date
    finishReason: PatrolStartFinishReasons
    roomsChecked?: number
    totalRooms?: number
    locations: string[]
    standaloneEvent?: DiagnosticEvent
}

export interface PatrolEventNote {
    noteSelectedActions?: string[]
    note?: string
}

export enum PatrolPointAction {
    NAVIGATE_TO = 'navigate_to',
    OPEN_PEEK_CLOSE = 'open_peek_close',
    FIND_AND_DISINFECT = 'find_and_disinfect',
    FIND_AND_DISINFECT_WITH_LAMP = 'find_and_disinfect_with_lamp',
}

export interface PatrolPoint {
    uid: string
    action: PatrolPointAction
    enabled: boolean
}

export interface PatrolPointConfig {
    patrolPoint: PatrolPoint
    enabled: boolean
    uv_target_fluence?: SanitizationLevel
}

export interface DiagnosticEvent {
    siteId: string
    robotId: string
    notificationUid: string
    viewed?: boolean
    isAssistance: boolean
    date: Date
    patrolPointConfig?: PatrolPointConfig
    debugInfo?: string
    faceId?: string
    toLocation?: string
    fromLocation?: string
    hasPhoto?: boolean
    reason: DiagnosticReason
    skip_reason?: SkipReason
    note?: PatrolEventNote
    originalModule?: string
}

export interface CurrentEventsForPreview {
    patrolId: string
    siteId: string
    robotId: string
    events: DiagnosticEvent[]
}

export interface ScheduleByRobot {
    [robotId: string]: Schedule
}

export interface SettingsByRobot {
    [robotId: string]: Settings[]
}

export interface PatrolDetails {
    id: string
    status: PatrolEventLevel
    startedAt: Date
    finishedAt?: Date
    roomsChecked?: number
    totalRooms?: number
    finishReason?: PatrolStartFinishReasons
    startReason?: PatrolStartFinishReasons
    roomSettings?: Settings
    events: DiagnosticEvent[]
    modules?: string[]
}

interface ReportReducer {
    details: PatrolDetails | null
    historyTabIndex: number
    finishedReports: SimplePatrol[]
    hasMoreReports: boolean
    isLoadingDetails: boolean
}

export interface NSPReducer {
    autoOpenSelectConfiguration: boolean
    isSavingSchedule: boolean
    isLoadingSchedule: boolean
    isLoadingSettings: boolean
    isSavingEventNote: boolean
    isShowingPreconditions: boolean
    eventSaveSuccess: boolean
    finishedPatrolId: string | null
    report: ReportReducer
    robotsWithPendingChanges: string[]
    scheduleByRobot: ScheduleByRobot
    settingsByRobot: SettingsByRobot
    viewedEvents: string[]
    siteSettings: {
        isLoadingLocations: boolean
        locations: Locations
        configuration: LocationsConfiguration
        locationForEdit: string | null
        isStatsModalOpen: boolean
        notifications: {
            notificationsEnabled: boolean
            notificationSettings: {[locationId: string]: boolean | undefined}
        }
    }
    dashboard: {
        isPresetsModalOpen: boolean
        isRobotPositionModalOpen: boolean
    }
    reminders: {
        remindersToShow: ReminderDisplayItem[]
        isSaving: boolean
    }
    patrolEvents: PatrolEventsStore
    schedule: ScheduleStore
    videoData: {url: string, title: string} | null
}

export interface ExtendEventTitleHookContext {
    diagnosticEvent?: DiagnosticEvent
    locations?: Locations
    t: TFunction
    options: {[key: string]: string | boolean | undefined | null | object | number}
}

export type ExtendEventTitleOptionsCallback = (params: ExtendEventTitleHookContext) => void

export type ExtendEventSummaryTextOptionsCallback = ExtendEventTitleOptionsCallback

export interface ExtendReportDetailsTabsParams {
    tabs: Array<{titleKey: string, iconComponent: React.FC, component: React.FC<any>}>
    modules: string[]
}

export type ExtendReportDetailsTabsCallback = (params: ExtendReportDetailsTabsParams) => void

export interface HistoryListTabsParams {
    tabs: Array<{titleKey: string, component: React.FC<any>}>
    configModules: string[]
}

export type ExtendHistoryListTabsCallback = (params: HistoryListTabsParams) => void

export interface GetReportMainLocationParams {
    details: PatrolDetails | null
    locations: Locations
    mainLocation: string
}

export interface MainActionButtonConfig {
    command?: PATROL_COMMAND
    action?: () => void
    route?: string
    titleKey: string
}

export interface GetPatrolImageHookContext {
    robot?: Robot | null
    stateGroup?: StateMachineGroup
}

export interface GetOperationFinishedHookContext {
    currentModule: string
}

export interface ExtraComponentsHookContext {
    components: Array<{order: number, module: string, Component: React.FC}>
}

export interface ExtendScheduleSectionsHookContext {
    sections: Array<{order: number, module: string, Component: React.FC}>
}

export type ExtendScheduleSectionsCallback = (params: ExtendScheduleSectionsHookContext) => void

export interface OperationPreconditions {
    batteryValid: boolean
    chargerValid: boolean
    itemsValid: boolean
    usingAC: boolean
    [key: string]: boolean
}

export interface LocationInterface {
    nextLocationUid: string
    lastLocationUid: string
}

export interface PatrolPointCounts {
    checkedPoints: number
    skippedPoints: number
    totalPoints: number
}

export interface PatrolMonitor extends PatrolPointCounts {
    notificationId: number
    nextPatrolPointUid: string
    nextPatrolPointAction: string
    lastPatrolPointUid: string
    lastPatrolPointAction: string
}

export interface MissionMonitor {
    missionUid: string
}

export interface NavigationInfo {
    isOperationOngoing: boolean
}

export enum StateMachineGroup {
    ASSISTANCE_PAUSED = 'group_assistance_paused',
    INITIALIZING = 'group_initializing',
    IDLE = 'group_idle',
    IDLE_AWAY = 'group_idle_away',
    CHECKING_PRECONDITIONS = 'group_checking_preconditions',
    STOPPED = 'group_stopped',
    STOPPED_RECOVERED = 'group_stopped_recovered',
    GOING_HOME = 'group_going_home',
    GOING_HOME_PAUSED = 'group_going_home_paused',
    PATROLLING_PAUSED_PREEMPTING = 'group_patrol_paused_preempting',
    GOING_HOME_ASSISTANCE = 'group_going_home_assistance',
    PATROLLING = 'group_patrol',
    PATROLLING_PAUSED = 'group_patrol_paused',
    PATROLLING_ASSISTANCE = 'group_patrol_assistance',
    CRITICAL_ERROR = 'group_critical_error',
    CRITICAL_BATTERY = 'group_critical_battery',
    STEALTH_MISSION = 'group_stealth_mission',
    STEALTH_MISSION_ASSISTANCE = 'group_stealth_mission_assistance',
    STEALTH_MISSION_PAUSED = 'group_stealth_mission_paused',
    RESTARTING = 'group_restarting',
    OPENING_GRIPPER = 'group_opening_gripper',
    OPENING_GRIPPER_PREEMPTING = 'group_opening_gripper_preempting',
    OPENING_GRIPPER_PREEMPTING_PAUSED = 'group_opening_gripper_preempting_paused',
}

export const eventStateGroups: Array<StateMachineGroup | undefined> = [
  StateMachineGroup.CRITICAL_BATTERY,
  StateMachineGroup.CRITICAL_ERROR,
  StateMachineGroup.STOPPED,
  StateMachineGroup.STOPPED_RECOVERED,
  StateMachineGroup.PATROLLING_ASSISTANCE,
  StateMachineGroup.GOING_HOME_ASSISTANCE,
  StateMachineGroup.STEALTH_MISSION_ASSISTANCE,
]

export const movementStateGroups: Array<StateMachineGroup | undefined> = [
  StateMachineGroup.PATROLLING,
  StateMachineGroup.GOING_HOME,
  StateMachineGroup.STEALTH_MISSION,
]
export const goingHomeStateGroups: Array<StateMachineGroup | undefined> = [
  StateMachineGroup.GOING_HOME_ASSISTANCE,
  StateMachineGroup.GOING_HOME_PAUSED,
  StateMachineGroup.GOING_HOME,
]

export const idleStates: Array<StateMachineGroup | undefined> = [
  StateMachineGroup.IDLE,
  StateMachineGroup.IDLE_AWAY,
  StateMachineGroup.CHECKING_PRECONDITIONS,
]

export const stateGroupsForOngoingPatrolEvents: Array<StateMachineGroup | undefined> = [
  StateMachineGroup.PATROLLING,
]

export const stateGroupsForPatrolCounters: Array<StateMachineGroup | undefined> = [
  StateMachineGroup.PATROLLING,
  StateMachineGroup.GOING_HOME,
]

export const statesForStartAction: Array<StateMachineGroup | undefined> = [
  StateMachineGroup.IDLE,
  StateMachineGroup.IDLE_AWAY,
]

export const statesForReturnHomeAction: Array<StateMachineGroup | undefined> = [
  StateMachineGroup.IDLE_AWAY,
]

export const statesForEstopRecoveryAction: Array<StateMachineGroup | undefined> = [
  StateMachineGroup.STOPPED_RECOVERED,
]

export const statesForResumeOperationAction: Array<StateMachineGroup | undefined> = [
  StateMachineGroup.PATROLLING_PAUSED,
  StateMachineGroup.PATROLLING_ASSISTANCE,
  StateMachineGroup.STEALTH_MISSION_PAUSED,
  StateMachineGroup.STEALTH_MISSION_ASSISTANCE,
]

export const statesForContinueToHomeAction: Array<StateMachineGroup | undefined> = [
  StateMachineGroup.GOING_HOME_PAUSED,
  StateMachineGroup.GOING_HOME_ASSISTANCE,
]

export const statesForResumeToHomeAction: Array<StateMachineGroup | undefined> = [
  StateMachineGroup.PATROLLING_PAUSED_PREEMPTING,
]

export interface StateMachine {
    rawState: string
    stateGroup: StateMachineGroup
    isTransitive: boolean
}

export interface SkippedEventImageHookContext {
    url: string
    modules: string[]
}

export interface SettingsConfigurationElement {
    id: string
    index: number
    name?: string
    selected: number
    total: number
    scenario?: string
}

export interface NextSchedule {
    date: Date
    item: ScheduleItem
}

export interface NextSchedule {
    date: Date
    item: ScheduleItem
}
