import {connect} from 'react-redux'
import {compose} from 'redux'
import {ThunkDispatch} from 'redux-thunk'
import {Store} from 'types'
import {changePatrolState} from '../actions'

import EstopRecoverAction, {Props} from './EstopRecoverAction'
import {PATROL_COMMAND} from '../types'
import {getStateMachine} from '../selectors'


const mapStateToProps = (state: Store) => {
  const robotId = state.app.selectedRobot?.id
  const siteId = state.app.selectedRobot?.siteId

  return {
    stateGroup: getStateMachine(state)?.stateGroup,
    hasEventWithAlarm: true,
    siteId,
    robotId,
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  dispatch,
  changePatrolState: compose(dispatch, changePatrolState),
})

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: ReturnType<typeof mapDispatchToProps>
): Props => ({
  ...stateProps,
  changeState: (command: PATROL_COMMAND) => {
    if (stateProps.robotId) {
      dispatchProps.changePatrolState(stateProps.robotId, command)
    }
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(EstopRecoverAction)
