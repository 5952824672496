import styled from 'styled-components'
import {warning, grayText} from '../../../colors'
import SettingsSubtitleContainer from './SettingsSubtitleContainer'
import TutorialVideo from './TutorialVideo'
import EventVideoTutorial from './EventVideoTutorial'

const LocationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  max-width: 100vw;
  color: ${(props: {isUnknown?: boolean}) => props.isUnknown ? grayText : 'inherit'};
  position: relative;
  width: 100%;
  justify-content: center;
`

const LocationWrapperForMeasure = styled(LocationWrapper)`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    visibility: hidden;
    z-index: -1;    
    max-width: 100vw;
`

const LocationContent = styled.div`
  min-height: ${(props: {minHeight?: string, center?: boolean}) => props.minHeight || '40px'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props: {minHeight?: string, center?: boolean}) => props.center ? 'center' : 'flex-start'};
  white-space: pre-wrap;
  max-width: 40%;
`

const LocationText = styled.div`
  color: ${(props: {unknownLocation?: boolean, isSingleText?: boolean, bold?: boolean}) => props.unknownLocation ? warning : 'inherit'};
  word-break: break-word;
  line-height: 1rem;
  
   ${(props: {unknownLocation?: boolean, isSingleText?: boolean, bold?: boolean}) => props.bold ? `
    font-weight: 500;
    display: block;
  ` : ''};
`

const ActionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 12px 0;
    
    > *:not(:first-child) {
      margin-top: 6px;
    }
`

const SharedLoaderWrapper = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    margin: 40px 0;
`

export {
  ActionsWrapper,
  LocationWrapperForMeasure,
  LocationContent,
  LocationText,
  LocationWrapper,
  SettingsSubtitleContainer,
  TutorialVideo,
  EventVideoTutorial,
  SharedLoaderWrapper,
}
