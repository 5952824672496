import React, {useEffect, useState} from 'react'
import {getTimeDifferenceString, useModuleAwareTranslation} from 'utils'
import {DiagnosticReason} from 'modules/navigation/types'
import * as Styled from './StyledElements'
import {getSeverity} from './utils'

interface Props {
  reason: string
  noTimeAgo?: boolean
  noTitleColor?: boolean
}

const EstopRecoverTitle: React.FC<Props> = ({
  reason,
  noTimeAgo,
  noTitleColor,
}: Props) => {
  const [, setRenderCounter] = useState(0)
  const [eventDate] = useState<Date>(new Date())
  const {t} = useModuleAwareTranslation()

  useEffect(() => {
    if (noTimeAgo) {
      return
    }

    const interval = setInterval(() => {
      setRenderCounter(counter => counter + 1)
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  const time = getTimeDifferenceString(eventDate)
  return (
    <Styled.EventTitle
      severity={getSeverity(reason === DiagnosticReason.ESTOP ? DiagnosticReason.ESTOP : DiagnosticReason.ESTOP_RECOVERED )}
      noTitleColor={noTitleColor}
    >
      {t(`Nav_Event title ${reason}`)}
      {!noTimeAgo && <div>{`(${t('Nav_Event content time ago', {time})})`}</div>}
    </Styled.EventTitle>
  )
}

export default EstopRecoverTitle
