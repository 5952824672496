import React from 'react'
import useDimensions from 'react-use-dimensions'
import {useAppToolbarHeight, useModuleAwareNormalTranslation} from 'utils'
import * as Styled from './StyledElements'
import {DiagnosticEvent, DiagnosticReason, Locations, StateMachineGroup} from 'modules/navigation/types'
import EventImage from './EventImage'
import EventTitle from './EventTitle'
import EstopRecoverTitle from './EstopRecoverTitle'
import EventDescriptionList from './EventDescriptionList'
import {StatusMainContent} from '../NavigationElements'
import Loader from '../Loader'
import {getEventPhotoUrl} from '../../modules/navigation/utils'
import {useSelector} from 'react-redux'
import {Store} from '../../types'
import {getStateMachine} from '../../modules/navigation/selectors'
import {selectors} from 'shared'
import {useTranslation} from 'react-i18next'

export interface Props {
  location?: React.ReactNode
  hideEvents?: boolean
  children?: React.ReactNode
  event?: DiagnosticEvent
  locations?: Locations
  options?: {
    customDescription?: string
    fullImage?: boolean
    noTimeAgo?: boolean
    noTitleColor?: boolean
  }
  originalModule?: string[]
}

const getMaxImageHeight = (toolbarHeight: number) => {
  const margins = 64
  const areaForTexts = 250
  return window.innerHeight - toolbarHeight - margins - areaForTexts
}

const GenericEventDisplay: React.FC<Props> = ({
  children,
  event,
  options,
  locations,
  hideEvents,
  location,
  originalModule,
}: Props) => {
  const {i18n} = useTranslation()
  const guiUsecase = useSelector((state: Store) => selectors.getGuiUsecase(state))
  const {t} = useModuleAwareNormalTranslation(guiUsecase, originalModule)
  const stateGroup = useSelector((state: Store) => getStateMachine(state)?.stateGroup)
  const [useDimensionsRef, dimensions] = useDimensions()
  const toolbarHeight = useAppToolbarHeight(false)
  if (hideEvents) {
    return (
      <>
        {children}
      </>
    )
  }

  const maxImageHeight = options?.fullImage && dimensions ?
    Math.min(dimensions.width * 0.75, getMaxImageHeight(toolbarHeight)) :
    undefined

  const maybeDescKey = event && `Nav_Event content ${event.reason}`
  const descriptionKey = maybeDescKey && i18n.exists(maybeDescKey) ? maybeDescKey : undefined
  const listKey = descriptionKey && i18n.exists(`${descriptionKey} list`) ? `${descriptionKey} list` : undefined

  const isEstop = stateGroup === StateMachineGroup.STOPPED ? DiagnosticReason.ESTOP : DiagnosticReason.ESTOP_RECOVERED
  return (
    <StatusMainContent ref={useDimensionsRef}>
      {event ? (
        <>
          <EventImage
            photo={event.hasPhoto ? getEventPhotoUrl(event.siteId, event.robotId, event.notificationUid) : undefined}
            reason={event.reason}
            fullImage={options?.fullImage}
            maxHeight={maxImageHeight !== undefined ? Math.ceil(maxImageHeight) : undefined}
          />
          <Styled.EventsTextWithActions fullImage={options?.fullImage}>
            <Styled.EventTexts>
              <EventTitle
                locations={locations}
                event={event}
                originalModule={originalModule}
                noTimeAgo={options?.noTimeAgo}
                noTitleColor={options?.noTitleColor || stateGroup === StateMachineGroup.STOPPED_RECOVERED}
              />
              {location}
              {(descriptionKey || options?.customDescription) && (
                <>
                  <Styled.EventDescription
                    $isLarge={stateGroup === StateMachineGroup.STOPPED || stateGroup === StateMachineGroup.STOPPED_RECOVERED}
                  >
                    {typeof options?.customDescription === 'string' ? options.customDescription : t(descriptionKey as string)}
                  </Styled.EventDescription>
                  {listKey && <EventDescriptionList translationKey={listKey} />}
                </>
              )}
              {event.debugInfo && (
                <Styled.DebugInfo>
                  {event.debugInfo}
                </Styled.DebugInfo>
              )}
            </Styled.EventTexts>
          </Styled.EventsTextWithActions>
        </>
      ) : (
        stateGroup === StateMachineGroup.STOPPED || stateGroup === StateMachineGroup.STOPPED_RECOVERED ? (<>
          <EventImage
            photo={undefined}
            reason={isEstop}
            fullImage={options?.fullImage}
            maxHeight={maxImageHeight !== undefined ? Math.ceil(maxImageHeight) : undefined}
          />
          <Styled.EventsTextWithActions fullImage={options?.fullImage}>
            <Styled.EventTexts>
              <EstopRecoverTitle
                reason={isEstop}
                noTimeAgo={false}
                noTitleColor={options?.noTitleColor || stateGroup === StateMachineGroup.STOPPED_RECOVERED}
              />
              {
                <>
                  <Styled.EventDescription
                    $isLarge={true}
                  >
                    {t(`Nav_Event content ${isEstop}`)}
                  </Styled.EventDescription>
                </>
              }
            </Styled.EventTexts>
          </Styled.EventsTextWithActions>
        </>) :( <Loader centered flexParent />)
      )}
    </StatusMainContent>
  )
}

export default GenericEventDisplay
